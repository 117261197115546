import { PROJECT_ADMIN_STATUS_TYPE, QUOTATION_KIND, USER_TYPE } from 'models/SupplementEnum';
import { urlCompiler } from './compiler';

/**
 *  route Link url 관련 constants
 */
export const LOGIN = '/';
export const LOGOUT = '/auth/logout';

export const USER_LIST = '/user';
export const USER_DETAIL = '/user/detail/[id]';

export const PROJECT_LIST = '/project';
export const PROJECT_DETAIL = `/project/detail`;
export const PROJECT_SEMI_AUTO_SEARCH = '/project/detail/semiAutoSearch';
export const PROJECT_SEMI_AUTO_ITEM = '/project/detail/simpleSemiAutoItem';
export const PROJECT_DETAIL_QUOTATION_CONTENT = `${PROJECT_DETAIL}/quotationContent`;
export const PROJECT_DETAIL_FINISH = `${PROJECT_DETAIL}/finish`;

export const SELL_REQUEST_LIST = '/project/sell';
export const SELL_REQUEST_DETAIL = '/project/sell/detail/[id]';

export const NOTIFICATION = '/notification';
export const NOTIFICATION_SEND = '/notification/send';

export const STEELBOSO_DATA_DASHBOARD = '/dashboard';

export const SEO_BLOG = '/seo/blog';

export const CATEGORY = '/category';

export const SYNONYM_MANAGE = '/synonym';

export const QUOTATION_STATUS = '/quotation/status';

export const PRODUCT_SPEC_ANALYSYS = '/spec/analysis';

export type ProjectListPath = {
  page?: number;
  projectName?: string;
  rfqNumber?: string;
  companyName?: string;
  managerName?: string;
  finish?: boolean;
  progress?: string;
  closeStatus?: string;
};

export type SendNotificationPath = {
  quotationId?: number;
};

export const userListPath = urlCompiler<{
  page?: string;
  email?: string;
  name?: string;
  phone?: string;
  company_name?: string;
  type?: USER_TYPE;
}>(USER_LIST);

export const projectListPath = urlCompiler<{
  page?: number;
  project_name?: string;
  rfq_number?: string;
  company_name?: string;
  manager?: string;
  finish?: boolean;
  progress?: string;
  kind?: string;
  sub_kind?: string;
}>(PROJECT_LIST);

export const sellReqeustListPath = urlCompiler<{
  page?: string;
  company_name?: string;
  name?: string;
}>(SELL_REQUEST_LIST);

export const projectDetailPath = urlCompiler<{
  kind?: QUOTATION_KIND;
  rfqNumberId?: number;
  quotationId?: number;
}>(PROJECT_DETAIL);
export const projectDetailQuotaionContentPath = urlCompiler<{
  kind?: QUOTATION_KIND;
  rfqNumberId?: number;
  quotationId?: number;
  orderId?: number;
}>(PROJECT_DETAIL_QUOTATION_CONTENT);
export const projectDetailQuotaionFinishPath = urlCompiler<{
  kind?: QUOTATION_KIND;
  rfqNumberId?: number;
  quotationId?: number;
  orderId?: number;
}>(PROJECT_DETAIL_FINISH);

export type QuotationStatusParamterType = {
  page?: number;
  size?: number;
  query?: string;
  field?: string;
};

export const quotationStatusPath = urlCompiler<QuotationStatusParamterType>(QUOTATION_STATUS);

export const projectSemiAutoSearchPath = urlCompiler<{
  rfqNumberId?: number;
  quotationId?: number;
  kind?: QUOTATION_KIND;
}>(PROJECT_SEMI_AUTO_SEARCH);

export const projectSemiAutoItemPath = urlCompiler<{
  rfqNumberId?: number;
  quotationId?: number;
  kind?: QUOTATION_KIND;
}>(PROJECT_SEMI_AUTO_ITEM);

export const notificationListPath = urlCompiler<ProjectListPath>(NOTIFICATION);

export const notificationSendPath = urlCompiler<SendNotificationPath>(NOTIFICATION_SEND);

export const synonymManagePath = urlCompiler<{ page?: number; target?: string; is_confirm?: string; value?: string }>(
  SYNONYM_MANAGE,
);

export const specAnalysisPath = urlCompiler<{}>(PRODUCT_SPEC_ANALYSYS);
